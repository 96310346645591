import './App.css';

function App() {

  return (
    <div className="App">
      <img src='logo192.png' alt='Minca'/>
    </div>
  );
}

export default App;
